<template>
    <div
        :class="{
            'content-center-login': !appIsInstalled,
            'content-center': appIsInstalled
        }"
        style="display: flex; flex-direction: column;"
    >
        <div class="container-sm mt40">
            <h2 class="title-center mb40 uppercase">
                {{ $t('auth.login') }}
            </h2>
            <div v-on:keyup.enter="onEnterSubmit">
                <label class="pure-material-textfield-outlined">
                    <input
                        placeholder=" "
                        type="text"
                        autocomplete="off"
                        autocapitalize="off"
                        spellcheck="false"
                        v-model="formData.email"
                        @blur="$v.formData.email.$touch()"
                        required
                    />
                    <span>{{ $t('global.email') }}</span>
                </label>

                <label class="pure-material-textfield-outlined mt16">
                    <input
                        placeholder=" "
                        autocomplete="off"
                        v-model="formData.password"
                        type="password"
                        @blur="$v.formData.password.$touch()"
                        required
                    />
                    <span>{{ $t('global.password') }}</span>
                </label>
            </div>

            <md-button
                class="mt16 uppercase"
                :class="{
                    'btn-default-blue': !$v.$invalid,
                    'btn-default-action': $v.$invalid
                }"
                style="margin-left:0;"
                @click="submit"
                >{{ $t('auth.signIn') }}</md-button
            >
        </div>

        <div v-if="!appIsInstalled" class="mt16 mb16 install-app flex">
            <div class="container-sm">
                <p>
                    {{ $t('auth.infomessageNewApp') }}
                </p>

                <md-button
                    class="default-button install-button"
                    style="margin-left:0;"
                    @click="install"
                    >{{ $t('auth.install') }}</md-button
                >
                <div class="column">
                    <md-button
                        class="c191456"
                        href="https://bus-do-polski.pl/przejazdy/jak-zainstalowac-aplikacje-dla-przewoznika"
                        rel="noopener"
                        target="_blank"
                        >{{ $t('auth.instruction') }}</md-button
                    >
                </div>
                <div style="margin-bottom:0px;">
                    {{ $t('auth.alsoAvailableOn') }}
                </div>
                <div class="one-row">
                    <div
                        @click="$toast.success($t('auth.isComingMessage'))"
                        class="pointer"
                    >
                        <img
                            alt="pobierz z AppStore"
                            src="@assets/images/app-store-badge.png"
                        />
                    </div>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.flotea.bus_do_polski_przewoznik&gl=PL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        rel="noopener"
                        >
                        <img alt="pobierz z Google Play" src="@assets/images/google-play-badge.png" />
                    </a>
                </div>
            </div>
        </div>

        <div class="container-sm column mt16">
            <md-button class="c191456" to="/reset-password">{{
                $t('auth.resetYourPassword')
            }}</md-button>
            <md-button
                href="https://bus-do-polski.pl/account"
                target="_blank"
                rel="noopener"
                class="c191456"
                >{{ $t('auth.createAccount') }}</md-button
            >
        </div>

        <div class="loading-overlay" v-if="loading">
            <div class="wrapper">
                <md-progress-spinner md-mode="indeterminate" :md-stroke="2" />
            </div>
        </div>
        <pwa-install
            v-if="!appIsInstalled"
            ref="installComponent"
            descriptionheader=""
            :installbuttontext="$t('auth.installbuttontext')"
            :cancelbuttontext="$t('auth.cancelbuttontext')"
            :iosinstallinfotext="$t('auth.iosinstallinfotext')"
            :explainer="$t('auth.explainer')"
            featuresheader=""
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import '@pwabuilder/pwainstall';

export default {
    validations: {
        formData: {
            email: { email, required },
            password: { required }
        }
    },

    data() {
        return {
            appIsInstalledOnIOS: false,
            loading: false,
            formData: { email: '', password: '' },
            screenshots: [
                {
                    src: './img/install1.avif',
                    sizes: '472x441',
                    type: 'image/gif'
                },
                {
                    src: './img/install2.avif',
                    sizes: '472x441',
                    type: 'image/gif'
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        }),

        appIsInstalled() {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                return true;
            } else {
                return false;
            }
        }
    },

    methods: {
        ...mapActions({
            login: 'auth/login'
        }),

        enterClicked() {
            alert('Enter clicked');
        },

        submit() {
            if (!this.$v.$invalid) {
                this.submitLoginForm();
            }
        },

        onEnterSubmit() {
            if (!this.$v.$invalid) {
                this.submitLoginForm();
            } else {
                this.$toast.error(this.$t('global.incorrectLoginCredentials'));
            }
        },

        async submitLoginForm() {
            this.loading = true;

            try {
                const data = await this.login({
                    email: this.formData.email,
                    password: this.formData.password
                });

                const redirectRoute =
                    this.$route.query.redirectFrom &&
                    this.$route.query.redirectFrom !== '/logout'
                        ? this.$route.query.redirectFrom
                        : { name: 'home' };

                this.$toast.success(
                    `${this.$t('global.welcomeBack')}, ${data[0].company_name}!`
                );
                this.$router.push(redirectRoute);
            } catch (error) {
                console.error(error);
                this.loading = false;
                this.$toast.error(this.$t('global.incorrectLoginCredentials'));
            }
        },

        install() {
            const iphone5 = window.screen.width <= 320;

            if (!iphone5) {
                this.$refs.installComponent.manifestdata.description =
                    'Instalacja aplikacji z poziomu przeglądarki wygląda różnie, w zależności od urządzenia i oprogramowania. Na powyższych gifach zobaczysz jak wygląda instalacja na iOS lub na Android. Jeżeli masz problem z instalacją aplikacji w ten sposób, spróbuj na innej przeglądarce lub po prostu zainstaluj aplikację dla przewoźnika ze sklepu.';
            } else {
                this.$refs.installComponent.manifestdata.description = '';
            }

            this.$refs.installComponent.manifestdata.screenshots = this.screenshots;
            this.$refs.installComponent.openmodal = true;
        }
    },

    mounted() {
        var self = this;

        window.appIsInstalled = data => {
            self.appIsInstalledOnIOS = true;
        };
    }
};
</script>
